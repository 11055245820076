export const ActionTypes = {
  GET__RENT__CONTENT: "GET__RENT__CONTENT",
  GET__RENT__INFO: "GET__RENT__INFO",
  GET__BUY__CONTENT: "GET__BUY__CONTENT",
  GET__BUY__INFO: "GET__BUY__INFO",
  REGISTER__START: "REGISTER__START",
  REGISTER__SUCCESS: "REGISTER__SUCCESS",
  REGISTER__FAILURE: "REGISTER__FAILURE",
  LOGIN__START: "LOGIN__START",
  LOGIN__SUCCESS: "LOGIN__SUCCESS",
  LOGIN__FAILURE: "LOGIN__FAILURE",
  LOGIN__RESET: "LOGIN__RESET",
  LOGOUT__START: "LOGOUT__START",
  LOGOUT__SUCCESS: "LOGOUT__SUCCESS",
  LOGOUT__FAILURE: "LOGOUT__FAILURE",
  SET__USER: "SET__USER",
  USER__DATA: "USER__DATA",
};
